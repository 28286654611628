<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <a href="#"> <i class="uil-home-alt"></i> </a>
            </router-link>
          </li>
          <li>
            <span> {{
              this.$t('report.company_dashboard.reports') }}
            </span>
          </li>
          <li>{{ this.$t('general.exam_detailed_report') }}</li>
        </ul>
      </nav>
    </div>

    <div class="section-header mb-lg-2 border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left">
          <span> {{ this.$t('general.exam_detailed_report') }} </span>
        </h2>
      </div>
    </div>
    <!--Modal Profie-->
    <div v-if="error" class="uk-alert-danger mb-3" uk-alert>
      <a class="uk-alert-close" uk-close></a>
      <p style="color:red;">
        <i class="uil-exclamation-triangle"></i> {{ error }}
      </p>
    </div>
    <!--End Modal Profile -->
    <div class="card">
      <!-- Card header -->
      <div class="card-header actions-toolbar border-0">
        <div class="d-flex justify-content-between align-items-center">
          <!-- <div>
            <a aria-expanded="false" class="btn btn-icon btn-hover btn-circle" href="javascript:void(0)">
              <i class="uil-search"></i>
            </a>
            <div class="uk-drop" style="left: 91px; top: 159.297px;"
                 uk-drop="mode: hover; pos: right-center; offset: 0">
                <span class="uk-search uk-search-navbar uk-width-1-1">
                  <input v-model="filter.search" :placeholder="$t('general.search')" autofocus=""
                         class="uk-search-input shadow-0 uk-form-small" type="search">
                </span>
            </div>
          </div> -->
          <div class="row" style="width:100%">
            <div class=" col-md-1  search-icon ">
              <i class="uil-search"></i>
            </div>
            <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
              <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="filter.search"
                :placeholder="$t('general.search')" autofocus="" />
            </div>
          </div>
          <!-- <h4 class="d-inline-block mb-0"></h4> -->
          <div class="d-flex">
            <a :uk-tooltip="$t('filters.order.sort')" aria-expanded="false" class="btn btn-icon btn-hover  btn-circle"
              href="javascript:void(0)" title="">
              <i class="uil-list-ui-alt"></i>
            </a>
            <div class="uk-dropdown" uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
              <ul class="uk-nav uk-dropdown-nav">
                <li class="uk-active">
                  <a href="javascript:void(0)" @click="sortOrder('asc', 'name')">{{ $t("sorting.a_z") }}</a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="sortOrder('desc', 'name')">{{ $t("sorting.z_a") }}</a>
                </li>
              </ul>
            </div>
            <!-- Filter -->
            <a class="btn btn-icon btn-hover  btn-circle" href="javascript:void(0)"
              :uk-tooltip="$t('general.filter_list')">
              <i class="uil-filter"></i>
            </a>
            <div class="large" id="filterDropdown"
              uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
              <div class="filter-container">
                <div class="uk-child-width-1-2@s uk-grid-small" uk-grid>
                  <div>
                    <h5 class="uk-text-bold mb-2">Eğitim</h5>
                    <multiselect v-model="assignment_id" :allow-empty="false" :options="assignmentList"
                      :searchable="true" deselectLabel="" label="name" placeholder="Eğitim Seçiniz"
                      selectLabel="Seçiniz" tag-placeholder="Eğitim Seçiniz" track-by="id"
                      @input="getExams(assignment_id.id)">
                      <template slot="option" slot-scope="option">
                        {{ option.option.name }}

                      </template>
                    </multiselect>
                  </div>
                  <div>
                    <h5 class="uk-text-bold mb-2">Sınav</h5>
                    <multiselect v-model="exam_id" :allow-empty="false" :options="examList" :searchable="true"
                      deselectLabel="" label="name" placeholder="Sınav Seçiniz" selectLabel="Seçiniz"
                      tag-placeholder="Sınav Seçiniz" track-by="id" />
                  </div>
                </div>
                <div class="uk-flex uk-flex-right p-2">
                  <button class="btn btn-default grey" @click="filterResult">
                    <i class="uil-filter"></i>
                    {{ $t("general.filter") }}
                  </button>
                </div>
              </div>
            </div>
            <a class="btn btn-icon btn-hover  btn-circle" href="javascript:;" :uk-tooltip="$t('filters.export_excell')"
              @click="exportExamResults">
              <i class="fa-file-excel fa"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- Table -->
      <default-loading v-if="report_loading"></default-loading>
      <template v-else>
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th scope="col">{{ $t('general.first_name_and_last_name') }}</th>
                <th scope="col">{{ this.$t('general.assignment_name') }}</th>
                <th scope="col">{{ this.$t('general.exam_name') }}</th>
                <th scope="col">Aldığı Puan</th>
                <th scope="col">{{ this.$t('general.created_at') }}</th>
                <th scope="col">Başlama Tarihi</th>
                <th scope="col">{{ $t('ajadam.end_date') }}</th>
                <th scope="col">{{ this.$t('general.average_grade') }}</th>
                <th scope="col">{{ this.$t('general.correct_answer_rate') }}</th>
                <th scope="col">{{ this.$t('general.wrong_answer_rate') }}</th>
                <th scope="col">{{ this.$t('general.passing_grade') }}</th>
              </tr>
            </thead>
            <tbody class="list">
              <template v-for="item in formattedItems">
                <tr>
                  <td>{{ item.firstname }} {{ item.surname }}</td>
                  <td>{{ item.assignmentName }}</td>
                  <td>{{ item.examName }}</td>
                  <td>{{ item.point.toFixed(2) }}</td>
                  <td>{{ item.examCreatedAt }}</td>
                  <td>{{ item.startDate }}</td>
                  <td>{{ item.endDate }}</td>
                  <td>{{ item.exam_avarage.toFixed(2) }}</td>
                  <td>{{ item.exam_right_answer_avarage }}</td>
                  <td>{{ item.exam_wrong_answer_avarage }}</td>
                  <td>{{ item.passmark }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <pagination :pagination="pagination" class="mt-2 mb-3" @changePage="setPage($event)"></pagination>
      </template>
    </div>
  </div>
</template>

<script>
import reports, {
  ERROR,
  GET_ITEMS,
  ITEMS,
  LOADING,
  MODULE_NAME,
  SUCCESS,
} from "@/core/services/store/reports.module";

import store from "@/core/services";
import moment from "moment";
import axios from "axios";
import Multiselect from "vue-multiselect";
import UserProfile from "@/view/company/User/UserProfile";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import Pagination from "@/assets/components/Pagination";
const _MODULE_NAME = MODULE_NAME;

export default {
  name: "ReportSurveyResults",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_MODULE_NAME, reports);
  },
  components: {
    UserProfile, Multiselect, DefaultLoading,
    Pagination
  },
  data() {
    return {
      pagination: null,
      exam_id: null,
      assignment_id: null,
      page: 1,
      per_page: 10,
      sort_by: "id",
      sort_desc: "desc",
      formattedItems: [],
      examList: [],
      assignmentList: [],
      error: null,
      filter: {
        search: null,
        order: "desc",
        sort: "id",
      },
      successMessage: "",
    };
  },
  computed: {
    items: {
      get() {
        return store.getters[_MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    report_loading: {
      get() {
        return store.getters[_MODULE_NAME + "/" + LOADING];
      },
      set(value) { },
    },
    errors: {
      get() {
        return store.getters[_MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    success() {
      return store.getters[_MODULE_NAME + "/" + SUCCESS];
    },
  },
  methods: {
    exportExamResults() {
      axios
        .get(
          process.env.VUE_APP_BACKEND_APP_URL +
          "/api/report/export/exam-results",
          {
            responseType: "blob",
            params: {
              exam_id: this.exam_id ? this.exam_id.id : null,
              assignment_id: this.assignment_id ? this.assignment_id.id : null,
              sort: this.filter.sort,
              order: this.filter.order,
              like: this.filter.search,
              report: Math.random() * 9999999
            },
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.secureLocalStorage("token"),
            },
          }
        )
        .then((response) => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let day = moment().format("YYYYMMDDHHMMSS");
          let fileName = "survey_result_report_list_" + day + ".xlsx";
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    filterResult(filter = null) {
      let self = this;
      this.error = null;
      if (!self.exam_id) {
        this.error = "Lütfen filtreden Eğitim ve Sınav seçimi yapınız";
        return false;
      }

      // UIkit.dropdown("#filterDropdown").hide();

      let filters = {
        like: self.filter.search,
        page: self.page,
        per_page: self.per_page,
        sort: self.filter.sort,
        order: self.filter.order,
        exam_id: self.exam_id ? self.exam_id.id : null,
        assignment_id: self.assignment_id ? self.assignment_id.id : null,
      };

      store
        .dispatch(_MODULE_NAME + "/" + GET_ITEMS, {
          url: "api/report/exam-results",
          filters: filters,
        })
        .then((response) => {
          if (response.status) {
            let results = [];
            this.pagination = response.data.data;
            let items = response.data.data;
            let exam = response.data.exam;
            items.data.forEach((item) => {
              let isValid = item.exam_id;

              let rightAnswerCount = exam.user_results_sum_right_answer_count
                ? exam.user_results_sum_right_answer_count
                : 0;
              let rightAnswerPercent = rightAnswerCount
                ? (
                  (rightAnswerCount /
                    exam.questions_count /
                    exam.user_results_count) *
                  100
                ).toFixed(2)
                : 0;

              let wrongAnswerCount = exam.user_results_sum_wrong_answer_count
                ? exam.user_results_sum_wrong_answer_count
                : 0;
              let wrongAnswerPercent = wrongAnswerCount
                ? (
                  (wrongAnswerCount /
                    exam.questions_count /
                    exam.user_results_count) *
                  100
                ).toFixed(2)
                : 0;

              results.push({
                examName: item.examName,
                assignmentName: item.assignmentName,
                firstname: item.name,
                surname: item.surname,
                examCreatedAt: item.examCreatedAt
                  ? moment(item.examCreatedAt).format("DD/MM/YYYY HH:mm")
                  : "-",
                startDate:
                  isValid && item.start_date
                    ? moment(item.start_date).format("DD/MM/YYYY HH:mm")
                    : "-",
                endDate:
                  isValid && item.end_date
                    ? moment(item.end_date).format("DD/MM/YYYY HH:mm")
                    : "-",
                point: isValid && item.point ? item.point : 0,
                exam_avarage: exam.user_results_sum_point
                  ? exam.user_results_sum_point / exam.user_results_count
                  : 0,
                exam_right_answer_avarage: `${rightAnswerCount} - ${rightAnswerPercent}`,
                exam_wrong_answer_avarage: `${wrongAnswerCount} - ${wrongAnswerPercent}`,
                passmark: exam.passmark,
              });
            });
            this.formattedItems = results;
          }
        });
    },
    setPage(page) {
      this.page = page;
      this.filterResult();
    },
    getAssignments() {
      let filters = {
        page: 0,
        order: "id",
        sort: "desc",
        content_file_category_id: 12
      };
      store
        .dispatch(_MODULE_NAME + "/" + GET_ITEMS, {
          url: "api/assignment",
          filters: filters,
        })
        .then((response) => {
          if (response.status) {
            let results = [];
            let items = response.data;
            items.forEach((item) => {
              results.push({
                id: item.id,
                name: item.full_name,
                order_number: item.order_number,
              });
            });
            this.assignmentList = results;
          }
        });
    },
    getExams(assignment_id = null) {
      let filters = {
        per_page: 0,
        sort: "id",
        order: "desc",
        fields: "id,name",
        assignment_id: assignment_id,
      };

      store
        .dispatch(_MODULE_NAME + "/" + GET_ITEMS, {
          url: "api/exam",
          filters: filters,
        })
        .then((response) => {
          if (response.status) {
            let results = [];
            let items = response.data;
            items.forEach((item) => {
              results.push({
                id: item.id,
                name: item.name,
              });
            });
            this.examList = results;
          }
        });
    },
    sortOrder(order, sort) {
      this.page = 1;
      this.filter.order = order;
      this.filter.sort = sort;

      this.filterResult();
    },
    setPage(page) {
      this.page = page;
      this.filterResult();
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    getFilter(value) {
      this.page = 1;
      this.filterResult(value);
    },
  },
  mounted() {
    this.filterResult();
    this.getAssignments();
    this.debouncedFilterSearch = _.debounce(this.filterResult, 500);

    if (this.$route.params.succesMessage != null) {
      this.successMessage = this.$route.params.succesMessage;
      this.scrollToTop();
    }
  },
  watch: {
    success: function (val) {
      if (val) {
        this.filterResult();
      }
    },
    "filter.search": function (val) {
      this.debouncedFilterSearch();
    },
  },
};
</script>

<style scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.uk-alert {
  margin: 0;
}
</style>
<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
